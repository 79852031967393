
    .table-data-box {
        height: calc(100% - 190px);
        .page-content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .el-table {
            :deep(.resource-table-header) {
                background-color: #005469;
                color: #fff;
            }
        }
    }
    .upload-resources-dialog {

        ::v-deep .el-dialog__header {
            background: #409EFF;

            .el-dialog__title {
                color: #ffffff;
            }

            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }

        .info-form {
            .file {
                ::v-deep .el-form-item__content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                }

                .file-name {
                    display: flex;
                    position: relative;
                }

                .upload-cover {
                    width: 100px;
                    height: 40px;
                    background: #409EFF;
                    border-radius: 4px;
                    color: #ffffff;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i.iconfont {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }

                .cover-input {
                    position: absolute;
                    top: 0;
                    width: 100px;
                    height: 30px;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .resources-class-cascader {
                width: 100%;
            }

            .select-course {
                width: 100%;
            }
        }
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #fff;


        .resource-classify {
            display: flex;
            align-items: center;
            padding: 26px 30px;
            position: relative;

            .head-title {
                font-size: 18px;
            }

            .back-btn {
                position: absolute;
                right: 30px;
            }

            span {
                display: inline-block;
                line-height: 1;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                font-weight: 400;
                cursor: pointer;
            }

            .class-item {
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                color: #409EFF;
            }

            &:after {
                content: '';
                position: absolute;
                left: 36px;
                right: 33px;
                bottom: 0;
                height: 1px;
                background: #e9e9e9;
            }
        }

        .administration {
            /*display: flex;*/
            .administration-box {
                padding: 0 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .admin-btn {
                    padding: 8px 28px;
                }
            }
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            ::v-deep .el-scrollbar__view {
                height: 100%;
            }

            .course-ware {
                padding: 0px 30px;
                display: flex;
                flex-wrap: wrap;
                min-height: 90%;

                .dataTabble {
                    width: 100%;
                    margin-top: 18px;
                    border-bottom: 1px solid #DDDDDD;

                    .table-head {
                        width: 100%;
                        height: 50px;
                        background: #E6F0FE;;

                        .table-head-title {
                            height: 50px;
                            font-size: 16px;

                            th {
                                color: #333333;
                                font-weight: 400;
                                width: 25%;
                            }

                            .th-title1 {
                                text-align: left;
                                padding-left: 40px;
                            }
                        }
                    }

                    .table-body {
                        width: 100%;
                        height: 100%;
                        border-bottom: 1px solid #DDDDDD;

                        .table-trBox {
                            position: relative;

                            td {
                                display: block;
                                height: 40px;
                                text-align: center;
                            }

                            .td-title {
                                text-align: left;
                                padding-left: 40px;
                            }

                            .opt-box {
                                a {
                                    display: flex;
                                    align-items: center;
                                    width: 33%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-box {
            margin-top: 20px;
            text-align: center;
            margin-bottom: 20px;
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            position: relative;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                padding: 6px;
                display: flex;
                position: relative;

                .new-module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 15px;
                }

                .module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 24px;
                    margin-right: 13px;
                }

                .item-cover {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }
                }

                .item-cover1 {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }

                    .show-img {
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .img-box {
                            width: 50px;
                            height: 50px;
                            border-radius: 24px;
                        }
                    }
                }

                .show1 {
                    background: #61BCEB;
                }

                .show2 {
                    background: #3DCAC8;
                }

                .show3 {
                    background: #F0BC49;
                }

                .show4 {
                    background: #EC75A4;
                }

                .show5 {
                    background: #79ACDC;
                }

                .show6 {
                    background: #61BCEB;
                }

                .show7 {
                    background: #5AB9E9;
                }

                .courseware {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .textbook {
                    background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                }

                .lesson-plan {
                    background: linear-gradient(0deg, #53DED1, #2BB9BF);
                }

                .micro-lesson {
                    background: linear-gradient(0deg, #EE89B5, #EA5D90);
                }

                .animation {
                    background: linear-gradient(0deg, #F39E72, #EC6C6C);
                }

                .test-center {
                    background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                }

                .training-center {
                    background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                }

                .knowledge-points {
                    background: linear-gradient(0deg, #77C9F1, #47AEE4);
                }

                .material-library {
                    background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                }

                .case-library {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .related-copywriting {
                    background: linear-gradient(0deg, #8BC3EB, #628EC9);
                }

                .personal-info {
                    background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                }

                .del-btn {
                    width: 18px;
                    height: 18px;
                    background: #FF0000;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }

                .show-title {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                }

                .new-module-item:before {
                    content: "";
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                }


                .new-module-item:hover:before {
                    width: 100%;
                    left: 0;
                    opacity: 0.5;
                }

                .new-module-item:hover .del-btn {
                    opacity: 1;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #409EFF;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                //line-height: 60px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                font-weight: 400;
                margin-right: 20px;

                &:hover {
                    cursor: pointer;
                }
            }

            .adhibition-btn {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
                width: 60px;
                height: 60px;
                background: #409EFF;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    ::v-deep .saveTemplate {
        .el-dialog__header {
            background: #409EFF;

            span {
                color: #fff;
                font-size: 16px;
            }

            .el-icon-close {
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 70px 0px 20px 50px;
        }

        .template-box {
            display: flex;
            flex-direction: column;

            .template-box-name {
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 14px;
                padding-right: 60px;
            }

            .template-box-btn {
                margin-top: 70px;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;

                .el-button {
                    margin-left: 20px;
                }
            }
        }
    }

    .select-positioning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
        border-radius: 10px;
        //position: absolute;
        //right: -20px;
        //top: -78px;
        //z-index: 99;
        background: #409EFF;
        i {
            font-size: 29px;
            color: #9CA9C3;
        }

        .hover-two {
          background: #409EFF;
          color: #FFFFFF;
          font-size: 14px;
          //line-height: 60px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-weight: 400;
          cursor: pointer;
          align-items: center;
          width: 100%;
          height: 100%;
        }
    }

    .temp-popover {
        display: flex;
        flex-direction: column;

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                span {
                    color: #409EFF;
                }
            }

            span {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }
